import { Button } from '@chakra-ui/button';
import { InputGroup, InputRightElement } from '@chakra-ui/input';
import { Box, Heading } from '@chakra-ui/react';
import useSearchStepSubmission from '@components/Analytics/events/SearchStepSubmission';
import useSearchSubmission from '@components/Analytics/events/SearchSubmission';
import {
  StringToIconKeys,
  STRING_TO_ICON_COMPONENT,
} from '@components/RenderIcon';
import useSearch from '@hooks/useSearch';
import dynamic from 'next/dynamic';
import { useContext, useMemo, useState } from 'react';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import { HeadingElements, HeadingSizes } from '~/@types/heading';
import SiteContext from '~/contexts/SiteContext';
import { Metadata } from '~/types/Magnolia';
import {
  StyledMiniSearchAutocompleteInput,
  StyledMiniSelfSearchInput,
} from './MiniSelfSearchStyles';

const Container = dynamic(
  () => import('@components/LayoutStructure/Container')
);

const FullWidthContainer = dynamic(
  () => import('@components/LayoutStructure/FullWidthContainer')
);

export interface CallToActionProps {
  text?: string;
  placeholder?: string;
  textColor?: string;
  bgColor?: string;
  maxWidth?: string;
  maxHeight?: string;
  fullWidth?: boolean;
  buttonOutsideInput?: boolean;
  icon?: StringToIconKeys;
  miniSelfSearchId: string;
  metadata?: Metadata;
  title?: string;
  headingElement?: HeadingElements;
  desktopHeadingSize?: HeadingSizes;
  headingSize?: HeadingSizes;
  titleAlignment?: 'left' | 'center' | 'right';
  inputAlignment?:
    | 'left'
    | 'center'
    | 'right'
    | 'flex-start'
    | 'flex-end'
    | 'start'
    | 'end';
  enablePredictiveSearch?: boolean;
}

const MiniSelfSearch: React.FC<CallToActionProps> = (props) => {
  const {
    maxWidth,
    text,
    textColor,
    bgColor,
    icon,
    buttonOutsideInput = false,
    maxHeight,
    placeholder = 'Enter City, State or Zip',
    miniSelfSearchId,
    title,
    headingElement,
    desktopHeadingSize,
    headingSize,
    titleAlignment,
    inputAlignment = 'center',
    enablePredictiveSearch = false,
  } = props;
  const { buildSearchUrl } = useSearch();
  const [keyword, setKeyword] = useState('');
  const siteContext = useContext(SiteContext);
  const domain = siteContext.site?.path || '';
  const searchPageUrl = buildSearchUrl({ keyword });

  const getIcon = (iconName?: StringToIconKeys) => {
    if (!iconName) return '';
    return { leftIcon: STRING_TO_ICON_COMPONENT[iconName] };
  };
  const StyledInput = useMemo(
    () => StyledMiniSelfSearchInput(domain),
    [domain]
  );

  const StyledAutocompleteInput = useMemo(
    () => StyledMiniSearchAutocompleteInput(domain),
    [domain]
  );

  const searchStepSubmission = useSearchStepSubmission();
  const searchSubmission = useSearchSubmission();
  const handleSearchEvents = () => {
    const stepInstanceId = uuidv4();
    const searchTemplateId = miniSelfSearchId ?? props?.metadata?.['@id'];
    const stepId = uuidv5(placeholder, searchTemplateId);
    const searchInstanceId = uuidv4();

    searchStepSubmission({
      search_template_id: searchTemplateId,
      search_instance_id: searchInstanceId,
      step_id: stepId,
      step_instance_id: stepInstanceId,
      step_index: 1,
      step_content: [
        {
          prompt_id: uuidv5(placeholder, searchTemplateId),
          prompt_type: 'text',
          prompt_instance_id: uuidv4(),
          prompt_index: 1,
          prompt_value: placeholder,
          response_array: [
            {
              response_value: keyword,
              response_id: uuidv4(),
            },
          ],
        },
      ],
    });

    searchSubmission({
      search_template_id: searchTemplateId,
      search_instance_id: searchInstanceId,
      step_submissions: [
        {
          step_id: stepId,
          step_instance_id: stepInstanceId,
          step_index: 1,
        },
      ],
    });
  };

  return (
    <>
      {title && (
        <Heading
          as={headingElement}
          size={{
            base: headingSize,
            md: desktopHeadingSize ? desktopHeadingSize : headingSize,
          }}
          textAlign={titleAlignment}
          style={{ scrollMarginTop: 48 }}
          pb={5}
        >
          {title}
        </Heading>
      )}
      <Box display="flex" width="100%" justifyContent={inputAlignment}>
        <InputGroup
          display="flex"
          gap={2}
          alignContent="start"
          maxWidth={maxWidth}
          py={2}
        >
          {enablePredictiveSearch && (
            <StyledAutocompleteInput
              setKeyword={setKeyword}
              pr={buttonOutsideInput ? 0 : '33%'}
              pl={{ base: 1, lg: 3 }}
              fontSize={{ base: 'sm', md: 'md', lg: 'xl' }}
              placeholder={placeholder}
              aria-label="search-keyword"
              height="4rem"
              maxHeight={buttonOutsideInput ? maxHeight : undefined}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSearchEvents();
                  location.assign(searchPageUrl);
                }
              }}
            />
          )}
          {!enablePredictiveSearch && (
            <StyledInput
              value={keyword || ''}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
              pr={buttonOutsideInput ? 0 : '33%'}
              pl={{ base: 1, lg: 3 }}
              autoComplete="shipping address-line1"
              fontSize={{ base: 'sm', md: 'md', lg: 'xl' }}
              placeholder={placeholder}
              aria-label="search-keyword"
              height="4rem"
              maxHeight={buttonOutsideInput ? maxHeight : undefined}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSearchEvents();
                  location.assign(searchPageUrl);
                }
              }}
            />
          )}
          {!buttonOutsideInput ? (
            <InputRightElement
              height="100%"
              width="33%"
              display="flex"
              justifyContent="end"
            >
              <Button
                as="a"
                mr={{ base: 1, md: 3 }}
                data-testid="mini-self-search-button"
                height="3rem"
                colorScheme={bgColor}
                variant="solid"
                color={textColor}
                fontSize={{ base: 'sm', lg: 'md' }}
                fontWeight={700}
                onClick={() => handleSearchEvents()}
                href={searchPageUrl}
                {...getIcon(icon)}
              >
                {text}
              </Button>
            </InputRightElement>
          ) : (
            <Button
              as="a"
              data-testid="mini-self-search-button-outside"
              height={buttonOutsideInput ? maxHeight : '100%'}
              width="fit-content"
              colorScheme={bgColor}
              variant="solid"
              color={textColor}
              fontSize={{ base: 'sm', lg: 'md' }}
              fontWeight={700}
              px="3rem"
              onClick={() => handleSearchEvents()}
              href={searchPageUrl}
              {...getIcon(icon)}
            >
              {text}
            </Button>
          )}
        </InputGroup>
      </Box>
    </>
  );
};

const MiniSelfSearchWrapper: React.FC<CallToActionProps> = ({
  fullWidth,
  ...otherProps
}) => {
  return fullWidth ? (
    <FullWidthContainer>
      <MiniSelfSearch {...otherProps} />
    </FullWidthContainer>
  ) : (
    <Container>
      <MiniSelfSearch {...otherProps} />
    </Container>
  );
};

export default MiniSelfSearchWrapper;
