import { Input, InputProps } from '@chakra-ui/input';
import { chakra, ChakraStyledOptions } from '@chakra-ui/react';
import AutocompleteInput from '@components/AutocompleteInput/AutocompleteInput';

const SiteStyles = (domain): ChakraStyledOptions => {
  const baseStyle: InputProps = {
    bgColor: 'white',
    height: '4.8rem',
    fontSize: { base: 'xl', lg: 'md', xl: 'xl' },
  };
  switch (domain) {
    case 'seniorhomes.com':
      return {
        baseStyle: {
          ...baseStyle,
          boxShadow: 'md',
        },
      };
    case 'caring.com':
      return {
        baseStyle: {
          ...baseStyle,
          border: '1px ',
          borderColor: 'gray.400',
        },
      };
    default:
      return baseStyle;
  }
};

export const StyledMiniSelfSearchInput = (domain): typeof Input => {
  return chakra(Input, { ...SiteStyles(domain) });
};

export const StyledMiniSearchAutocompleteInput = (
  domain
): typeof AutocompleteInput => {
  return chakra(AutocompleteInput, { ...SiteStyles(domain) });
};
